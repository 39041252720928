// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/general/accueil/backgroundSelector/useBackgroundSelector.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/general/accueil/backgroundSelector/useBackgroundSelector.tsx");
  import.meta.hot.lastModified = "1724943802153.1643";
}
// REMIX HMR END

import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import BackgroundSelectorComponent from "./BackgroundPopup";
import useBackgroundImage from "./useBackgroundImage";
const ToggleBackgroundSelector = props => {
  return <button className="clickable borderless-btn border-none d-flex 
        align-items-center justify-content-center p-0 round-btn white-seethrough" onClick={() => props.setPopupVisible(true)}>
      <FontAwesomeIcon icon={faEllipsis} className="medium-icon " />
    </button>;
};
_c = ToggleBackgroundSelector;
const useBackgroundSelector = () => {
  _s();
  const [popupVisible, setPopupVisible] = useState(false);
  const {
    isActiveBackgroundImage,
    selectImage,
    cancelSelection,
    validateSelection,
    temporaryBgId
  } = useBackgroundImage();
  const toggleBackgroundSelector = <ToggleBackgroundSelector setPopupVisible={setPopupVisible} />;
  const backgroundSelectorComponent = <BackgroundSelectorComponent setPopupVisible={setPopupVisible} popupVisible={popupVisible} isActiveBackgroundImage={isActiveBackgroundImage} selectImage={selectImage} cancelSelection={cancelSelection} validateSelection={validateSelection} />;
  return {
    popupVisible,
    setPopupVisible,
    toggleBackgroundSelector,
    backgroundSelectorComponent,
    isActiveBackgroundImage,
    selectImage,
    cancelSelection,
    validateSelection,
    temporaryBgId
  };
};
_s(useBackgroundSelector, "34cea83FbbjE58MB3dbE3Op26R4=", false, function () {
  return [useBackgroundImage];
});
export default useBackgroundSelector;
var _c;
$RefreshReg$(_c, "ToggleBackgroundSelector");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;