{
  "fr-CH": {
    "calendar": {
      "today": "Heute"
    },
    "schedule": {
      "day": "journée",
      "week": "La semaine",
      "workWeek": "Semaine de travail",
      "month": "Mois",
      "year": "An",
      "agenda": "Ordre du jour",
      "weekAgenda": "Agenda de la semaine",
      "workWeekAgenda": "Agenda de la semaine de travail",
      "monthAgenda": "Agenda du mois",
      "today": "Aujourd'hui",
      "noEvents": "Pas d'événements",
      "emptyContainer": "Aucun événement n'est prévu ce jour-là.",
      "allDay": "Toute la journée",
      "start": "Début",
      "end": "Fin",
      "more": "plus",
      "close": "Fermer",
      "cancel": "Annuler",
      "noTitle": "(Pas de titre)",
      "delete": "Supprimer",
      "deleteEvent": "Supprimer",
      "deleteMultipleEvent": "Supprimer plusieurs événements",
      "selectedItems": "Articles sélectionnés",
      "deleteSeries": "Série entière",
      "edit": "Éditer",
      "editSeries": "Série entière",
      "editEvent": "Modification",
      "createEvent": "Créer",
      "subject": "Matière",
      "addTitle": "Ajouter un titre",
      "moreDetails": "Plus de détails",
      "save": "Sauvegarder",
      "editContent": "Comment aimeriez-vous changer le rendez-vous de la série?",
      "deleteContent": "Voulez-vous vraiment supprimer cet événement?",
      "deleteMultipleContent": "Voulez-vous vraiment supprimer les événements sélectionnés?",
      "newEvent": "Nouvel évènement",
      "title": "Titre",
      "location": "Emplacement",
      "description": "La description",
      "timezone": "Fuseau horaire",
      "startTimezone": "Démarrer le fuseau horaire",
      "endTimezone": "Fin du fuseau horaire",
      "repeat": "Répéter",
      "saveButton": "Sauvegarder",
      "cancelButton": "Annuler",
      "deleteButton": "Supprimer",
      "recurrence": "Récurrence",
      "wrongPattern": "Le modèle de récurrence n'est pas valide.",
      "seriesChangeAlert": "Voulez-vous annuler les modifications apportées à des instances spécifiques de cette série et les associer à nouveau à l'ensemble de la série?",
      "createError": "La durée de l'événement doit être plus courte que sa fréquence. Raccourcissez la durée ou modifiez le modèle de récurrence dans l'éditeur d'événements de récurrence.",
      "sameDayAlert": "Deux occurrences du même événement ne peuvent pas se produire le même jour.",
      "occurenceAlert": "Vous ne pouvez pas reporter une occurrence du rendez-vous périodique si elle saute sur une occurrence ultérieure du même rendez-vous.",
      "editRecurrence": "Modifier la récurrence",
      "repeats": "Répète",
      "alert": "Alerte",
      "startEndError": "La date de fin sélectionnée se produit avant la date de début.",
      "invalidDateError": "La valeur de date entrée n'est pas valide.",
      "blockAlert": "Les événements ne peuvent pas être planifiés dans l'intervalle de temps bloqué.",
      "ok": "D'accord",
      "yes": "Oui",
      "no": "Non",
      "occurrence": "Occurrence",
      "series": "Séries",
      "previous": "précédent",
      "next": "Prochain",
      "timelineDay": "Jour de la chronologie",
      "timelineWeek": "Semaine chronologique",
      "timelineWorkWeek": "Semaine de travail",
      "timelineMonth": "Mois de la chronologie",
      "timelineYear": "Année de la chronologie",
      "editFollowingEvent": "Événements suivants",
      "deleteTitle": "Supprimer l'événement",
      "editTitle": "Modifier l'événement",
      "beginFrom": "Commencer à partir de",
      "endAt": "Fin à"
    },
    "recurrenceeditor": {
      "none": "Aucun",
      "daily": "du quotidien",
      "weekly": "Hebdomadaire",
      "monthly": "Mensuel",
      "month": "Mois",
      "yearly": "Annuel",
      "never": "Jamais",
      "until": "Jusqu'à",
      "count": "Compter",
      "first": "Premier",
      "second": "Seconde",
      "third": "Troisième",
      "fourth": "Quatrième",
      "last": "Dernier",
      "repeat": "Répéter",
      "repeatEvery": "Répétez tous les",
      "on": "Répéter sur",
      "end": "Fin",
      "onDay": "journée",
      "days": "Journées)",
      "weeks": "Semaines)",
      "months": "Mois)",
      "years": "Ans)",
      "every": "chaque",
      "summaryTimes": "fois)",
      "summaryOn": "sur",
      "summaryUntil": "jusqu'à",
      "summaryRepeat": "Répète",
      "summaryDay": "journées)",
      "summaryWeek": "semaines)",
      "summaryMonth": "mois)",
      "summaryYear": "ans)",
      "monthWeek": "Mois Semaine",
      "monthPosition": "Position du mois",
      "monthExpander": "Expander mois",
      "yearExpander": "Expander année",
      "repeatInterval": "Intervalle de répétition"
    }
  }
}
