// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/general/accueil/backgroundSelector/BackgroundPopup.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/general/accueil/backgroundSelector/BackgroundPopup.tsx");
  import.meta.hot.lastModified = "1724943802153.1643";
}
// REMIX HMR END

import { faCheck, faGear, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { Dialog } from "primereact/dialog";
import img00 from "~/assets/general_images/backgrounds/00-ImgFondQuai.jpg";
import img01 from "~/assets/general_images/backgrounds/01-ImgFondGrue.jpg";
import img02 from "~/assets/general_images/backgrounds/02-ImgFondOriginalSepia.jpg";
import img03 from "~/assets/general_images/backgrounds/03-ImgFondSoleilSepia.jpg";
import img04 from "~/assets/general_images/backgrounds/04-ImgFondPont.jpg";
import img05 from "~/assets/general_images/backgrounds/05-ImgFondPalmiers.jpg";
import img06 from "~/assets/general_images/backgrounds/06-ImgFondEtangVert.jpg";
import img99 from "~/assets/general_images/backgrounds/99-ImgFondUnie.jpg";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Header = () => {
  _s();
  const {
    t
  } = useTranslation();
  return <div className="d-flex align-items-center">
      <FontAwesomeIcon className="medium-icon-3 mr-2" icon={faGear} />
      <div>{t("dashbaord_background_title")}</div>
    </div>;
};
_s(Header, "zlIdU9EjM2llFt74AbE2KsUJXyM=", false, function () {
  return [useTranslation];
});
_c = Header;
const BackgroundSelectorComponent = props => {
  _s2();
  const {
    t
  } = useTranslation();
  const images = [{
    id: "00",
    src: img00
  }, {
    id: "01",
    src: img01
  }, {
    id: "02",
    src: img02
  }, {
    id: "03",
    src: img03
  }, {
    id: "04",
    src: img04
  }, {
    id: "05",
    src: img05
  }, {
    id: "06",
    src: img06
  }, {
    id: "99",
    src: img99
  }];
  return <div>
      <Dialog visible={props.popupVisible} modal onHide={() => {
      props.setPopupVisible(false);
      props.cancelSelection();
    }} className="grid-dialog thin-header " resizable={false} style={{
      maxWidth: "1100px"
    }} header={<Header />}>
        <div className="d-flex flex-wrap pt-3">
          {images.map(item => <div key={item.id} className={"background-image-selector-item-container " + (props.isActiveBackgroundImage(item.id) ? "active" : "")}>
              <button onClick={() => props.selectImage(item.id)} className="btn transparent-button p-0 background-image-selector-item ">
                <img src={item.src} alt="Optim background image" />
              </button>
            </div>)}
        </div>

        <div className="d-flex w-100 justify-content-end mt-4 pt-2">
          <Button onClick={() => {
          props.setPopupVisible(false);
          props.cancelSelection();
        }} type="button" className="btn pt-2 pb-2 mr-2 white-btn dialog-btn border-light-grey-important">
            <FontAwesomeIcon className="medium-icon mr-1" icon={faXmark} />{" "}
            {t("annuler")}
          </Button>
          <Button type="submit" className={`btn pt-2 pb-2 devis-btn dialog-btn white-text  border-none`} onClick={() => {
          props.setPopupVisible(false);
          props.validateSelection();
        }}>
            <FontAwesomeIcon className="medium-icon mr-1" icon={faCheck} />{" "}
            {t("valider")}
          </Button>
        </div>
      </Dialog>
    </div>;
};
_s2(BackgroundSelectorComponent, "zlIdU9EjM2llFt74AbE2KsUJXyM=", false, function () {
  return [useTranslation];
});
_c2 = BackgroundSelectorComponent;
export default BackgroundSelectorComponent;
var _c, _c2;
$RefreshReg$(_c, "Header");
$RefreshReg$(_c2, "BackgroundSelectorComponent");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;